<template>
  <div class="auth-wrapper auth-v2">
    <b-row
      class="auth-inner m-0"
      style="direction: ltr;"
    >

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
          lg="4"
          class="auth-bg"
      >
        <b-row>
          <b-col sm="12">
          <span class="brand-logo">
          <b-img
              :src="appLogoImage"
              alt="logo"
          />
        </span>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center auth-bg px-2 p-lg-5 form-auth">
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            تکمیل ثبت نام
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="registerVerifyForm"
            #default="{invalid}"
          >
            <div
              class="auth-register-form mt-2"

            >

              <!-- username -->
              <b-form-group
                  label="نام"
                  label-for="register-name"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required"
                >
                  <b-form-input
                      id="register-name"
                      v-model="user.name"
                      name="register-name"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                  label="نام خانوادگی"
                  label-for="register-family"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Family"
                    vid="family"
                    rules="required"
                >
                  <b-form-input
                      id="register-family"
                      v-model="user.family"
                      name="register-family"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- security code -->
              <b-form-group
                id="div-securitycode"
                label-for="register-mobile"
                label="کد تائید"
                style="direction: ltr"
              >
                  <b-form-input
                    v-model="code"
                    class="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1"
                    maxlength="5"
                    size="lg"
                    style="letter-spacing: .9rem;font-size: 2.1rem"
                    name="code"
                    rules="required"
                    @keydown="bl00=$event.target.value"
                    @keyup="queryForKeywords"
                    v-on:keyup="codeChangeEn($event)"
                  />
              </b-form-group>
              <p class="text-center mt-2">
                ارسال مجدد کد تا
                {{ timerCount }}
                دیگر
              </p>
              <b-button
                  @click="registerVerifyForm"
                variant="success"
                block
                type="submit"
                :disabled="invalid && loading"
              ><b-spinner variant="outline-primary" small  v-if="loading"></b-spinner>
                ارسال
              </b-button>
            </div>
          </validation-observer>

          <p class="text-center mt-2">
            <span>کدی ارسال نشده است؟</span>
            <b-link :to="{name:'register'}">
              <span style="font-weight: bolder;">&nbsp;تغییر شماره همراه</span>
            </b-link>
          </p>

        </b-col>
        </b-row>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import Vue from 'vue'
import store from '@/store/index'
import User from '@/models/user'
import { functions } from '@/functions'
import ModelService from "@/services/model.service";

Vue.prototype.$functions = functions

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: new User('', '', '', '', ''),
      code: '',
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/arrow-down.png'),
      // validation
      required,
      email,
      bl00: '1',
      timerEnabled: true,
      timerCount: 300,
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/arrow-down.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            // eslint-disable-next-line no-plusplus
            this.timerCount--
            if (this.timerCount === 0) {
              this.$router.push('/login')
              console.log(this.timerCount)
            }
          }, 1000)
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  beforeCreate() {
    if (typeof this.$mobileR === 'undefined') {
      this.$router.push('/login')
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  mounted() {
    const { isRTL } = $themeConfig.layout
    const formGroup = document.getElementsByClassName('form-auth')
    formGroup[0].style.direction = isRTL ? 'rtl' : 'ltr'
    document.getElementById('div-securitycode').getElementsByTagName('input')[0].focus()
  },
  methods: {
    registerVerifyForm() {
      this.$refs.registerVerifyForm.validate().then(success => {
        if (success) {
          this.loading = true
          const data = {
            countrycode: '+98',
            // eslint-disable-next-line radix
            mobileNo: parseInt(this.$mobileR, 10),
            otp: this.code,
            digits_reg_name: `${this.user.name} ${this.user.family}`,
          }
          this.$store.dispatch('auth/registerVerify', data).then(
            () => {
              ModelService.updateUser({ first_name: this.user.name, last_name: this.user.family }).then(
                  res => {
                    console.log(res)
                    localStorage.setItem('userData', JSON.stringify(res))
                    this.loading = false
                    this.$router.push('/')
                  },
              )
            }, error => {
              this.loading = false
              this.message = error.toString()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'register error',
                  text: this.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            },
          )
        }
      })
    },
    queryForKeywords(e) {
      this.$nextTick(() => {
        if (this.code.length === 5) {
          this.registerVerifyForm()
        }
      })
    },
    codeChangeEn(event) {
      this.code = this.$functions.fixNumbers(event.target.value)
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '../../@core/scss/vue/pages/page-auth.scss';
.brand-logo img{
  width: 70px;
}
.auth-wrapper .auth-bg{
  background-color: #ebedf2;
  color: #000;
}
.auth-wrapper label,.auth-wrapper span,.auth-wrapper h2,.auth-wrapper .brand-text{
  color: #000 !important;
}
</style>
