import axios from 'axios'
import authHeader from './auth-header'
import Config from '../../config'

const source = axios.CancelToken.source()
const serverAddress = `${Config.apiServers.url}`


class ModelService {
  // eslint-disable-next-line class-methods-use-this
  getDetail() {
    const user = JSON.parse(localStorage.getItem('user'))
    return axios.get(`${serverAddress}/wp-json/wp/v2/users/${user.data.user_id}`, { headers: authHeader() })
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  updateUser(data) {
    const user = JSON.parse(localStorage.getItem('user'))
    return axios.post(`${serverAddress}/wp-json/wp/v2/users/${user.data.user_id}`, data, { headers: authHeader() })
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getListAmir() {
    return axios.post(`${serverAddress}/app/Amir.json`, { }, { headers: authHeader() })
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getListAttribute() {
    return axios.post(`${serverAddress}/app/attribute.json`, { }, { headers: authHeader() })
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getListMotivation() {
    return axios.post(`${serverAddress}/app/motivation.json`, { }, { headers: authHeader() })
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getListObjects() {
    return axios.post(`${serverAddress}/app/objects.json`, { }, { headers: authHeader() })
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getInfo() {
    return axios.post(`${serverAddress}/app/info.json`, { }, { headers: authHeader() })
      .then(response => response.data)
  }
}

export default new ModelService()
